
import Button from "@/components/base/Button"
import { mdiAccountSupervisorCircle } from "@mdi/js"

export default {
    name: "ImpersonateUser",
    components: { Button },
    props: {
        userId: {
            type: [Number, String],
            required: true,
        },
    },
    data: () => ({
        mdiAccountSupervisorCircle,
    }),
    computed: {
        isIcon() {
            return this.$breakpoint.smAndDown
        },
    },
    methods: {
        impersonate() {
            this.$store.dispatch("account/impersonate", this.userId)
        },
    },
}
